import { Switch, Route } from 'react-router-dom';

import { Thanks } from '../pages/Thanks';

export function Paid() {
  return (
    <Switch>
      <Route path="/thanks" component={Thanks} />
      <Route component={Thanks} />
    </Switch>
  );
}
