import styled from 'styled-components';

export const Container = styled.div`
  width: ${props => (props.width ? props.width : '100%')};

  position: relative;
  display: flex;
  flex-direction: column;
`;

export const InputForm = styled.input`
  width: 100%;
  height: 3.5rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  background-color: var(--shape);
  border: 1px solid var(--text-border);
  outline: 0;
  padding: 0 1rem;

  &:focus-within {
    border: 1.5px solid var(--text-border);
  }
`;
