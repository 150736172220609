import { Container, InputForm } from './styles';

export function Input({ name, label, value, width, type, ...rest }) {
  return (
    <Container width={width}>
      {label ? <label htmlFor={name}>{label}</label> : ''}
      <InputForm type={type || 'text'} id={name} value={value} {...rest} />
    </Container>
  );
}
