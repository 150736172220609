import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCheckout } from '../../contexts/checkout';
import { formatToBRL, formatToCard, formatToValidity } from '../../utils/masks';
import { Header } from '../../components/Header';
import { Input } from '../../components/Input';
import { Alert } from '../../components/Alert';
import { Loading } from '../../components/Loading';
import { Container, Content, InputBlock, Footer } from './styles';

export function Checkout() {
  const history = useHistory();

  const encodedQuery = window.location.search.slice(1);
  const decodedQuery = Buffer.from(String(encodedQuery), 'base64').toString(
    'utf8',
  );
  const values = decodedQuery.split('&');
  const user = {};

  values.forEach(value => {
    const splitted = value.split('=');
    user[decodeURIComponent(splitted[0])] =
      decodeURIComponent(splitted[1]) || '';
  });

  if (user?.values) {
    user.values = JSON.parse(user.values);
  }

  const [offerValue, setOfferValue] = useState(user.amount * 100 || 0);
  const {
    cardOwnerName,
    cardNumber,
    cardValidity,
    cardCVV,
    paid,
    handleUser,
    handleCardOwnerName,
    handleCardNumber,
    handleCardValidity,
    handleCardCVV,
  } = useCheckout();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  function getCardType(value) {
    if (!value) {
      return null;
    }

    const types = {
      Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      Master: /^5[1-5][0-9]{14}$/,
      Amex: /^3[47][0-9]{13}$/,
      Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      Discover: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
      JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
      Elo: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^504175|^627780|^63(6297|6368|6369)|(65003[5-9]|65004[0-9]|65005[01])|(65040[5-9]|6504[1-3][0-9])|(65048[5-9]|65049[0-9]|6505[0-2][0-9]|65053[0-8])|(65054[1-9]|6505[5-8][0-9]|65059[0-8])|(65070[0-9]|65071[0-8])|(65072[0-7])|(65090[1-9]|6509[1-6][0-9]|65097[0-8])|(65165[2-9]|6516[67][0-9])|(65500[0-9]|65501[0-9])|(65502[1-9]|6550[34][0-9]|65505[0-8])|^(506699|5067[0-6][0-9]|50677[0-8])|^(509[0-8][0-9]{2}|5099[0-8][0-9]|50999[0-9])|^65003[1-3]|^(65003[5-9]|65004\d|65005[0-1])|^(65040[5-9]|6504[1-3]\d)|^(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|^(65054[1-9]|6505[5-8]\d|65059[0-8])|^(65070\d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091\d|650920)|^(65165[2-9]|6516[6-7]\d)|^(65500\d|65501\d)|^(65502[1-9]|6550[3-4]\d|65505[0-8])/,
      Aura: /^50[0-9]{14,17}$/,
    };

    const foundCard = Object.keys(types).find(type => types[type].test(value));

    return foundCard === -1 ? null : foundCard;
  }

  function validateForm(event) {
    event.preventDefault();

    const now = new Date();
    const yearNow = now.getFullYear();
    const monthNow = now.getMonth() + 1;

    if (!offerValue) {
      setError('Insira um valor de oferta válido');
      return;
    }

    if (!cardOwnerName.includes(' ')) {
      setError('Digite o nome idêntico ao apresentado no cartão');
      return;
    }

    if (!getCardType(cardNumber)) {
      setError('Digite um cartão válido');
      return;
    }
    if (!cardValidity || cardValidity.length !== 6) {
      setError('A data deve ser no formato MM/AAAA');
      return;
    }
    if (cardValidity.length === 6) {
      const month = parseInt(cardValidity.substring(0, 2), 10);
      const year = parseInt(cardValidity.substring(2), 10);
      if (month <= 0 || month > 12) {
        setError('Data Incorreta');
        return;
      }
      if (year < yearNow || (year === yearNow && month < monthNow)) {
        setError('Data de validade do cartão vencida');
        return;
      }
    }
    if (cardCVV.length < 3) {
      setError('Código CVV inválido');
      return;
    }

    handleUser(user);

    history.push('/resume');
  }

  useEffect(() => {
    if (error) {
      toast.error(error, {
        onClose: () => setError(''),
      });
    }
  }, [error]);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      <Header text="Checkout" />
      <Container>
        <Alert name={error ? 'Toastify__toast--error' : ''} />

        {loading ? (
          <Loading size="4rem" color="#111" />
        ) : (
          <>
            <Content>
              <header>
                <h2>Informações de pagamento</h2>
              </header>
              <form id="offer-form" onSubmit={validateForm}>
                <InputBlock>
                  <Input
                    name="offerValue"
                    value={formatToBRL(offerValue)}
                    label="Valor total da oferta"
                    onChange={e =>
                      setOfferValue(e.target.value.replace(/\D/g, ''))
                    }
                    readOnly
                    width="30%"
                  />
                </InputBlock>

                <InputBlock>
                  <Input
                    name="cardOwnerName"
                    className="cardOwnerName"
                    value={cardOwnerName}
                    label="Nome no cartão"
                    onChange={e =>
                      handleCardOwnerName(e.target.value.toUpperCase())
                    }
                    width="45%"
                  />
                  <Input
                    name="cardNumber"
                    value={formatToCard(cardNumber)}
                    label="Número do cartão"
                    onChange={e =>
                      handleCardNumber(e.target.value.replace(/\D/g, ''))
                    }
                    width="45%"
                  />
                </InputBlock>

                <InputBlock>
                  <Input
                    name="validity"
                    placeholder="MM/AAAA"
                    value={formatToValidity(cardValidity)}
                    label="Validade"
                    onChange={e =>
                      handleCardValidity(
                        e.target.value.replace(/\D/g, '').substring(0, 6),
                      )
                    }
                    width="45%"
                  />
                  <Input
                    name="cvv"
                    value={cardCVV.substring(0, 4)}
                    label="CVV"
                    type="password"
                    onChange={e =>
                      handleCardCVV(
                        e.target.value.replace(/\D/g, '').substring(0, 4),
                      )
                    }
                    width="45%"
                  />
                </InputBlock>
              </form>
            </Content>
            <Footer>
              <button type="submit" form="offer-form" disabled={paid}>
                Prosseguir
              </button>
            </Footer>
          </>
        )}
      </Container>
    </>
  );
}
