import { useCheckout } from '../../contexts/checkout';

import { Header } from '../../components/Header';
import { Container, Content } from './styles';

export function Thanks() {
  const { user } = useCheckout();

  return (
    <>
      <Header text="Concluído" />
      <Container>
        <Content>
          <h2>{`Agradecemos pela sua oferta, ${user.name} ;)`}</h2>
          <h3>Tudo certo!</h3>
          <p>Você já pode retornar ao app da Sara.</p>
        </Content>
      </Container>
    </>
  );
}
