import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  /* transform: scale(5); */

  div.loader {
    animation: is-rotating 1s infinite;
    border: 1px solid ${props => props.background || 'transparent'};
    border-radius: 50%;
    border-top-color: ${props => props.color || '#CDCDCD'};
    height: ${props => props.size || '2rem'};
    width: ${props => props.size || '2rem'};
  }

  @keyframes is-rotating {
    to {
      transform: rotate(1turn);
    }
  }
`;
