import styled from 'styled-components';

export const Container = styled.main`
  max-width: 1120px;
  margin: 0 auto;
  padding: 2.5rem 1rem;
`;

export const Content = styled.div`
  margin-top: -10rem;
  background: var(--color-line-in-white);
  padding: 1.5rem 2rem 3rem 2rem;

  border-left: 1px solid var(--border);
  border-right: 1px solid var(--border);
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.3);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  color: var(--text-title);

  header {
    margin: 1.5rem 0;

    h2 {
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 570px) {
    h2,
    h3 {
      text-align: center;
    }
  }
`;

export const InputBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 1rem;
  }

  input.cardOwnerName {
    text-transform: uppercase;
  }

  @media (max-width: 570px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    div {
      width: 100%;
    }

    div + div {
      margin-top: 1rem;
    }
  }
`;

export const Footer = styled.footer`
  width: 100%;
  background: var(--footer);

  display: flex;
  align-items: center;
  justify-content: flex-end;

  border: 1px solid var(--border);
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.3);

  padding: 1.5rem 2rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  button {
    font-size: 1rem;
    color: #fff;
    background: var(--red);
    border: 0;
    padding: 0 2rem;
    border-radius: 0.25rem;
    height: 3rem;
    outline-color: transparent;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;
