import { createContext, useContext, useState } from 'react';

const CheckoutContext = createContext({});

export function CheckoutProvider({ children }) {
  const [user, setUser] = useState({});
  const [cardOwnerName, setCardOwnerName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardValidity, setCardValidity] = useState('');
  const [cardCVV, setCardCVV] = useState('');
  const [church, setChurch] = useState('');

  const [paid, setPaid] = useState(false);

  function handleUser(value) {
    setUser(value);
  }

  function handleCardOwnerName(value) {
    setCardOwnerName(value);
  }

  function handleCardNumber(value) {
    setCardNumber(value);
  }

  function handleCardValidity(value) {
    setCardValidity(value);
  }

  function handleCardCVV(value) {
    setCardCVV(value);
  }

  function handlePaid(value) {
    setPaid(value);
  }

  function handleChurch(value) {
    setChurch(value);
  }

  return (
    <CheckoutContext.Provider
      value={{
        user,
        cardOwnerName,
        cardNumber,
        cardValidity,
        cardCVV,
        church,
        paid,
        handleUser,
        handleCardOwnerName,
        handleCardNumber,
        handleCardValidity,
        handleCardCVV,
        handleChurch,
        handlePaid,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
}

export function useCheckout() {
  const context = useContext(CheckoutContext);

  return context;
}
