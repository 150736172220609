import { useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

import logoImg from '../../assets/logo.svg';
import { Container, Content, LogoImage, HeaderText } from './styles';

export function Header({ text, canGoBack }) {
  const history = useHistory();

  return (
    <Container>
      <Content>
        {canGoBack ? <FiArrowLeft onClick={() => history.goBack()} /> : ''}
        <LogoImage src={logoImg} alt="Sara Nossa Terra" />
        <HeaderText>{text}</HeaderText>
      </Content>
    </Container>
  );
}
