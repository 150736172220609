import styled from 'styled-components';

export const Container = styled.main`
  max-width: 1120px;
  margin: 0 auto;
  padding: 2.5rem 1rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  margin-top: -10rem;
  background: var(--color-line-in-white);

  border: 1px solid var(--border);
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.3);

  padding: 4rem 2rem;
  border-radius: 0.25rem;
  color: var(--text-title);

  h2 {
    margin-bottom: 2rem;
  }

  @media (max-width: 575px) {
    h2 {
      font-size: 1rem;
    }
    h3 {
      font-size: 1rem;
    }
    p {
      font-size: 0.9rem;
    }
  }
`;
