import { Switch, Route } from 'react-router-dom';

import { Checkout } from '../pages/Checkout';
import { CheckoutPix } from '../pages/CheckoutPix';
import { Resume } from '../pages/Resume';

export function NoPaid() {
  return (
    <Switch>
      <Route exact path="/" component={Checkout} />
      <Route path="/pix" component={CheckoutPix} />
      <Route path="/resume" component={Resume} />
      <Route component={Checkout} />
    </Switch>
  );
}
