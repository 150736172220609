import { FiX } from 'react-icons/fi';

import { Toast } from './styles';

export function Alert({ name }) {
  return (
    <Toast
      toastClassName={name}
      progressClassName="Toastify__progress-bar"
      closeButton={() => <FiX size="1.5rem" color="#FFF" />}
    />
  );
}
