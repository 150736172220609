function formatToBRL(value) {
  const formatedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100);

  return formatedValue;
}

function formatToCard(value) {
  const formatedValue = value.replace(
    /(\d{4})(\d{4})(\d{4})(\d{4})/g,
    '$1 $2 $3 $4',
  );

  return formatedValue.substring(0, 19);
}

function formatToValidity(value) {
  const formatedValue = value.replace(/(\d{2})(\d{4})/g, '$1/$2');

  return formatedValue.substring(0, 7);
}

export { formatToBRL, formatToCard, formatToValidity };
