import styled from 'styled-components';

export const Container = styled.main`
  max-width: 1120px;
  margin: 0 auto;
  padding: 2.5rem 1rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: -10rem;
  background: var(--color-line-in-white);
  padding: 1.5rem 2rem 3rem 2rem;

  border-left: 1px solid var(--border);
  border-right: 1px solid var(--border);
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.3);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  color: var(--text-title);

  header {
    margin: 1.5rem 0;

    h2 {
      margin-bottom: 1rem;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h3 {
    margin: 1rem 0;
  }
  h4 {
    margin-bottom: 0.6rem;
  }

  button {
    font-size: 1rem;
    color: #fff;
    background: var(--red);
    border: 0;
    padding: 0 2rem;
    margin: 1rem 0;
    border-radius: 0.25rem;
    height: 3rem;
    outline-color: transparent;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  @media (max-width: 570px) {
    h2,
    h3 {
      text-align: center;
    }
  }
`;
