import { useCheckout } from '../contexts/checkout';

import { NoPaid } from './noPaid.routes';
import { Paid } from './paid.routes';

export function Routes() {
  const { paid } = useCheckout();

  return paid ? <Paid /> : <NoPaid />;
}
