import styled from 'styled-components';

export const Container = styled.header`
  background: var(--bg-header);
`;

export const Content = styled.div`
  max-width: 1120px;
  margin: 0 auto;

  position: relative;

  padding: 3rem 1rem 12rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    position: absolute;
    top: 0;
    left: 0;

    width: 2rem;
    height: 2rem;
    color: var(--shape);

    margin-top: 1.5rem;
    margin-left: 1rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.7);
    }
  }

  @media (max-width: 340px) {
    padding-bottom: 10rem;
  }
`;

export const LogoImage = styled.img`
  height: 6rem;

  border-right: 2px solid var(--text-border);

  padding-right: 2rem;

  @media (max-width: 340px) {
    height: 4rem;

    padding-right: 1rem;
  }
`;

export const HeaderText = styled.h1`
  padding-left: 2rem;
  color: #f5f5f5;

  @media (max-width: 340px) {
    font-size: 1.6rem;
    padding-left: 1rem;
  }
`;
