import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useCheckout } from '../../contexts/checkout';

import { api } from '../../services/api';

import { formatToValidity } from '../../utils/masks';

import { Header } from '../../components/Header';
import { Alert } from '../../components/Alert';
import { Loading } from '../../components/Loading';
import { Container, Content, Footer, ListProducts, ListItem } from './styles';

export function Resume() {
  const history = useHistory();

  const {
    user,
    cardOwnerName,
    cardNumber,
    cardValidity,
    cardCVV,
    paid,
    handlePaid,
  } = useCheckout();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  async function handleRequestPayment() {
    if (paid) {
      setError('Requisição de pagamento já foi realizada');
      return;
    }

    setLoading(true);
    const products = [];
    user.values.forEach(value => {
      value.products.forEach(product => {
        const price = product.text.replace(/\D/g, '') / 100;
        products.push({
          id: product.id,
          preco: price,
          quantidade: product.quantity || 1,
        });
      });
    });
    try {
      const dataProduct = {
        membro_id: user?.pessoa_id,
        forma_de_pagamento_id: 9,
        produtos: products,
        subcategoria_id: 11,
        grupo_id: user.grupo_id,
      };
      const requestProduct = await api.post(
        '/api/pedidos/publico',
        dataProduct,
      );

      const dataPayment = {
        installments: 1,
        pedido_id: requestProduct.data.data.id,
        creditcard_cvv: cardCVV,
        creditcard_cardnumber: cardNumber,
        creditcard_holder: cardOwnerName,
        creditcard_expirationdate: formatToValidity(cardValidity),
      };

      await api.post('/api/pagamentos/publico', dataPayment);

      handlePaid(true);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError(
          'Não foi possível concluir sua solicitação, por favor tente novamente mais tarde.',
        );
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (error) {
      toast.error(error, {
        onClose: () => setError(''),
      });
    } else if (success) {
      toast.success(success, {
        onClose: () => setSuccess(''),
      });
    }
  }, [success, error]);

  useEffect(() => {
    if (!user.values) history.replace('/');
  }, []);

  return (
    <>
      <Header text="Resumo" canGoBack={!paid} />
      <Container>
        <Alert
          name={error ? 'Toastify__toast--error' : 'Toastify__toast--success'}
        />
        <Content>
          <header>
            <h2>Informações da oferta</h2>
          </header>
          <ListProducts>
            {user?.values?.map(value => (
              <ListItem key={value.id}>
                <h3>{value.category}</h3>
                {value.products.map(product => (
                  <p key={product.id}>
                    {product.name} {product.text}
                  </p>
                ))}
              </ListItem>
            ))}
          </ListProducts>
        </Content>
        <Footer>
          <button
            type="button"
            disabled={loading || paid}
            onClick={handleRequestPayment}
          >
            {loading ? <Loading /> : 'Finalizar'}
          </button>
        </Footer>
      </Container>
    </>
  );
}
