import { BrowserRouter } from 'react-router-dom';

import { Routes } from './Routes';
import { CheckoutProvider } from './contexts/checkout';

import { GlobalStyle } from './styles/global';

export function App() {
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <CheckoutProvider>
          <Routes />
        </CheckoutProvider>
      </BrowserRouter>
    </>
  );
}
