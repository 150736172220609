/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react';
import QRCode from 'react-qr-code';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Header } from '../../components/Header';
import { Alert } from '../../components/Alert';
import { Loading } from '../../components/Loading';
import { Container, Content } from './styles';

export function CheckoutPix() {
  const [copied, setCopied] = useState(false);

  const encodedQuery = window.location.search.slice(1);
  const decodedQuery = Buffer.from(String(encodedQuery), 'base64').toString(
    'utf8',
  );
  const values = decodedQuery.split('&');
  const params = {};

  values.forEach(value => {
    const splitted = value.split('=');
    params[decodeURIComponent(splitted[0])] =
      decodeURIComponent(splitted[1]) || '';
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (copied) {
      toast.success('Copiado com sucesso!', {
        onClose: () => setCopied(false),
      });
    }
  }, [copied]);

  return (
    <>
      <Header text="Checkout | PIX" />
      <Container>
        <Alert name={copied ? 'Toastify__toast--success' : ''} />
        {loading ? (
          <Loading size="4rem" color="#111" />
        ) : (
          <>
            <Content>
              <header>
                <h2>Utilize o QR CODE ou copie o código</h2>
              </header>

              <main>
                <QRCode value={params?.qrCodeValue} size={200} />
                <button
                  type="button"
                  onClick={() => {
                    const text = params?.qrCodeValue;
                    navigator.clipboard.writeText(text).then(
                      () => {
                        setCopied(true);
                      },
                      err => {
                        console.error('Async: Could not copy text: ', err);
                      },
                    );
                  }}
                >
                  COPIAR CÓDIGO
                </button>
                <p>Esse código é válido até ás {params?.expirationTime}</p>

                <div>
                  <h3>INSTRUÇÕES</h3>
                  <div
                    style={{
                      alignItems: 'flex-start',
                    }}
                  >
                    <h4>1 - Copie o código do PIX acima.</h4>
                    <h4>
                      2 - Abra o app do seu banco. Escolha a opção pagar pelo
                      código PIX e cole o código.
                    </h4>
                    <h4>3 - Valide as informações e autorize o pagamento.</h4>
                    <h4>
                      4 - Pronto, seu pagamento será debitado da sua conta. Você
                      receberá a confirmação do pagamento no seu e-mail ou
                      através do menu Minhas Compras no aplicativo.
                    </h4>
                    <h4>
                      Importante! Envie seu comprovante de transferência para
                      sua liderança (Bispo, Pastor, Diácono ou Lider)
                    </h4>
                  </div>
                </div>
              </main>
            </Content>
          </>
        )}
      </Container>
    </>
  );
}
