import styled from 'styled-components';

export const Container = styled.main`
  max-width: 1120px;
  margin: 0 auto;
  padding: 2.5rem 1rem;
`;

export const Content = styled.div`
  margin-top: -10rem;
  background: var(--color-line-in-white);

  border-left: 1px solid var(--border);
  border-right: 1px solid var(--border);
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.3);

  padding: 1.5rem 2rem 3rem 2rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: var(--text-title);

  header {
    margin: 1.5rem 0;
  }

  @media (max-width: 570px) {
    h2 {
      text-align: center;
    }
  }
`;

export const ListProducts = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ListItem = styled.div`
  background: #ceced7;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--text-border);

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const Footer = styled.footer`
  width: 100%;
  background: var(--footer);

  border: 1px solid var(--border);
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.3);

  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 1.5rem 2rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  button {
    font-size: 1rem;
    color: #fff;
    background: var(--red);
    border: 0;
    padding: 0 2rem;
    border-radius: 0.25rem;
    height: 3rem;
    outline-color: transparent;

    letter-spacing: 0.5px;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;
